import {FETCH} from "../util/fetch"
import {MyHistoryMes} from "../api/serviceConfig"
import {Toast} from "antd-mobile"

const initState = {
    message:[],
    parameters:[],
    descriptions:''
}

const INIT = "INIT"
const GETMES = "GETMES"
const HASREADMES = "HASREADMES"
const DELMES = "DELMES"

export function historyMes(state=initState,action) {
    switch(action.type) {
        case INIT:
        case GETMES:
        case HASREADMES:
        case DELMES:
            return action.payload
        default:
            return state
    }
}

export function deleteAllMsgs() {
    return async (dispatch,getState) => {
        let parameters = getState().historyMes.parameters
        let descriptions = getState().historyMes.descriptions
        dispatch({
            type:DELMES,
            payload:{
                message:[],
                parameters:parameters,
                descriptions:descriptions
            }
        })
    }
}

export function getMyMes() {
    return async(dispatch,getState) => {
        FETCH(`${MyHistoryMes}?deviceId=${window.localStorage.getItem("deviceId")}`)
            .then(res=> {
                res.json().then(data=> {
                    let parameters = getState().historyMes.parameters
                    let descriptions = getState().historyMes.descriptions
                    if(data.code === 0) {
                        dispatch({
                            type:GETMES,
                            payload:{
                                message:data.data,
                                parameters:parameters,
                                descriptions:descriptions
                            }
                        })
                    }

                })
            })
            .catch(e=>{
                console.error(e)
                Toast.fail("网络连接缓慢",1)
            })
    }
}
export function hasReadMes(id) {
    return async(dispatch,getState) => {
        FETCH(MyHistoryMes,{
            method:"PATCH",
            mode:"cors",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                id
            })
        }).then(res=> {

                res.json().then(data=> {
                    if(data.code ===0) {
                        const message = getState().historyMes.message
                        let parameters = getState().historyMes.parameters
                        let descriptions = getState().historyMes.descriptions
                        let i = message.findIndex((v)=>v._id === id)
                        const str = JSON.stringify(message)
                        const newMes = JSON.parse(str)
                        newMes[i].hasRead = true

                        dispatch({
                            type:HASREADMES,
                            payload:{
                                message:newMes,
                                parameters:parameters,
                                descriptions:descriptions
                            }
                        })
                    }
                })
            })
            .catch(e=>{
                console.error(e)
                Toast.fail("网络连接缓慢",1)
            })
    }
}
export function delMes(id) {
    return async(dispatch,getState) => {
        FETCH(MyHistoryMes,{
            method:"DELETE",
            mode:"cors",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                id
            })
        }).then(res=> {

            res.json().then(data=> {
                if(data.code ===0) {
                    const message = getState().historyMes.message
                    let parameters = getState().historyMes.parameters
                    let descriptions = getState().historyMes.descriptions
                    let i = message.findIndex((v)=>v._id === id)
                    const str = JSON.stringify(message)
                    const newMes = JSON.parse(str)
                    console.log(newMes,i)
                    newMes[i].isDeleted = true

                    dispatch({
                        type:DELMES,
                        payload:{
                            message:newMes,
                            parameters:parameters,
                            descriptions:descriptions
                        }
                    })
                }
            })
        })
            .catch(e=>{
                console.error(e)
                Toast.fail("网络连接缓慢",1)
            })
    }
}

export function setParameters(parameters,descriptions) {
    return async(dispatch,getState) => {
        const message = getState().historyMes.message
        dispatch({
            type:DELMES,
            payload:{
                message:message,
                parameters:parameters,
                descriptions:descriptions
            }
        })
    }
}
