// import City from "./pca-code.json"
export const AppRootUrl = "http://47.94.139.223:9004"
// export const AppRootUrl = "http://10.10.11.152:22020"
// export const AppRootUrl = "http://47.94.139.223:9004"
export const GetServiceList = AppRootUrl + "/customer/servicelist"
export const DeleteThisAppMsgs = AppRootUrl + "/customer/msgs"
export const GetServiceDetail = AppRootUrl + "/customer/detail"
export const ChangeRssUrl = AppRootUrl + "/customer/service"
export const LoginUrl = AppRootUrl + "/customer/login"
export const SendCodeUrl = AppRootUrl + "/customer/sendVerifyCode"
export const CheckUpdateUrl = AppRootUrl + "/customer/checkLatest"
export const MyHistoryMes = AppRootUrl+"/customer/myMes"
export const SendFeedback = AppRootUrl+"/feedback/addFeedbackMsg"
export const SetAllowNotice = AppRootUrl+"/customer/setAllowNotice"
export const GetThisAllowedOrNot = AppRootUrl+"/customer/ifAllowed"
export const GetMyOpenid = AppRootUrl+"/wxController/getMyOpenid"
