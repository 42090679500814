import React from 'react'
import {List,InputItem,WingBlank,WhiteSpace,Button,Toast} from 'antd-mobile'
import "./login.css"
import { LoginUrl, SendCodeUrl,GetMyOpenid} from "../../api/serviceConfig"
import Logo from "./login.png"
import DisableBack from "../../component/disableBack/disableBack"
import axios from "axios"

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            phone:"",
            openSendCodeButton:true,
            waitSecond:0,
            code:""
        }
    }
    componentWillMount() {
        if (window.location.href.indexOf('?code=')>=0) {
            let index1 = window.location.href.indexOf('?code=')+6
            let index2 = window.location.href.indexOf('&state')
            let code = window.location.href.substring(index1,index2)
            axios(GetMyOpenid+`?code=${code}`)
                .then(data => {
                        if(data) {
                            window.localStorage.setItem("phone","openid"+data)
                            window.localStorage.setItem("deviceId","openid"+data)
                        } else {
                            console.log("error")
                        }
                })
                .catch(error=>{
                    console.log(error)
                })
            this.props.history.push('/myservice')
        }
        if(window.localStorage.getItem("token")) {
            this.props.history.push('/myservice')
        }
    }
    render(){
        return(
            <div
                className={"login-container"}
                style={{
                    top:"0px",
                    bottom:"0px",
                    position:"fixed"
                }}
            >
                <DisableBack/>
                <WingBlank>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    {/*<WhiteSpace/>*/}
                    {/*<WhiteSpace/>*/}
                    {/*<WhiteSpace/>*/}
                    {/*<WhiteSpace/>*/}
                    <div style={{
                        textAlign:"center"
                    }}><img style={{maxWidth:"118px"}} src={Logo} alt=""/></div>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    <WhiteSpace/>
                    {/*<WhiteSpace/>*/}
                    {/*<WhiteSpace/>*/}
                    {/*<WhiteSpace/>*/}
                    <List
                        className={"input-container"}
                    >
                        <InputItem
                            placeholder={"手机号"}
                            onChange={v => {
                                this.setState(
                                    {
                                        phone:v
                                    }
                                )
                            }}
                            style={{
                                background:"transparent"
                            }}
                        >
                        </InputItem>
                        <InputItem
                            onChange={v=>{
                                this.setState({
                                    code:v
                                })
                            }}
                            extra={
                                <Button
                                type="primary"
                                size="small"
                                inline
                                disabled = {!this.state.openSendCodeButton}
                                onClick={()=>{
                                    fetch(SendCodeUrl,{
                                        method:"POST",
                                        mode:"cors",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body:JSON.stringify({
                                            phone:this.state.phone,
                                            deviceId:window.localStorage.getItem("deviceId")
                                        })
                                    }).then(res => {
                                        res.json().then(data => {
                                            if(data.code === 0) {
                                                if(data.data.isNewUser) {
                                                    window.localStorage.setItem("isNewUser","isNewUser")
                                                }
                                                //获取验证码成功，启动等待
                                                this.setState({
                                                    openSendCodeButton:false,
                                                    waitSecond:30
                                                })
                                                let startIn = setInterval(()=>{
                                                    if(!this.state.openSendCodeButton && this.state.waitSecond === 0) {
                                                        clearInterval(startIn)
                                                        this.setState({
                                                            openSendCodeButton:true,
                                                            waitSecond:0
                                                        })
                                                    }
                                                    this.setState({
                                                        waitSecond:this.state.waitSecond - 1
                                                    })
                                                },1000)
                                            } else {
                                                Toast.fail("获取验证码失败")
                                            }
                                        })
                                    }).catch(err => {
                                        console.error(err)
                                        Toast.fail("网络连接缓慢，请稍后重试")
                                    })
                                }}
                            >
                                {this.state.openSendCodeButton ? "获取验证码" : `${this.state.waitSecond}秒后重试`}
                            </Button>}
                        >
                        </InputItem>
                    </List>
                    <WhiteSpace
                        style={{
                            backgroundColor:"transparent"
                        }}
                    />
                    <List>
                        <Button
                            // style={{backgroundColor:"#008ee5",color:"white",border:"0px"}}
                            type="primary"
                            // disabled = {this.state.openSendCodeButton}
                            onClick={()=>{
                                fetch(LoginUrl,{
                                    method:"POST",
                                    mode:"cors",
                                    headers: {
                                        "Content-Type": "application/json"
                                    },
                                    body:JSON.stringify({
                                        phone:this.state.phone,
                                        code:this.state.code,
                                        deviceId: window.localStorage.getItem("deviceId")
                                    })
                                })
                                    .then(res => {
                                        res.json().then(data=>{
                                        if(data.code === 0) {
                                            // window.sessionStorage.setItem("phone",this.state.phone)
                                            window.localStorage.setItem("phone",this.state.phone)
                                            window.localStorage.setItem("token",data.data.token)
                                            if(window.localStorage.getItem("isNewUser")) {
                                                this.props.history.push('/firstUse')
                                            } else {
                                                this.props.history.push('/myservice')
                                            }
                                        } else {
                                            if (this.state.phone===''){
                                                Toast.fail("请填入要登录的手机号",1)
                                            } else if (this.state.code === '') {
                                                Toast.fail("请获取并输入验证码",1)
                                            } else {
                                                Toast.fail("验证码错误",1)
                                            }
                                        }
                                    })
                                    .catch(err => {
                                        console.error(err)
                                        Toast.fail("网络连接缓慢，请稍后重试",1)
                                    })
                                })
                            }}
                        >登录</Button>
                    </List>
                </WingBlank>
            </div>
        )
    }
}

export default Login

