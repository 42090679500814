import React from 'react'
import { List ,SwipeAction ,SearchBar ,WhiteSpace, WingBlank,ListView} from 'antd-mobile'
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"

import {Toast} from "antd-mobile";
import {FETCH} from "../../util/fetch"
import EditImg from "../myService/edit.png"
import DelImg from "../myService/del.png"
import {getMyMes,hasReadMes,delMes} from "../../redux/historyMes.redux"
import './msgDetail.css'


@withRouter
@connect(
    state=>state.historyMes,
    {getMyMes,hasReadMes,delMes}
)
class msgDetail extends React.Component {
    constructor(props) {
        super(props)

        this.timestampToTime = this.timestampToTime.bind(this)
    }
    timestampToTime(timestamp) {
        var date = new Date(parseInt(timestamp));//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        let D = date.getDate() <10 ? '0'+ date.getDate()+' ': date.getDate()+' ';
        let h = date.getHours() <10 ? '0' + date.getHours() + ':': date.getHours() + ':';
        let m = date.getMinutes()<10 ? '0' + date.getMinutes() : date.getMinutes();
        // let s = date.getSeconds();
        return Y+M+D+h+m;
    }
    render() {
        const msgId = this.props.location.pathname.match( /\/msgDetail:id=(\S*)/)[1]
        let index = msgId.indexOf('&')
        let msId = msgId.substring(0,index)
        const Mes = this.props.message.filter(v => v._id === msId)[0]
        let content = Mes ? Mes.content:''
        let lcontent = ''
        if (content.length) {
            var textR = content;
            var reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g;
            lcontent= textR.replace(reg,"<a href='$1$2'>$1$2</a>");
    }
        return (
            Mes?
                <WingBlank>
                    <div style={{margin: '15px 0'}}>
                        {/*<span className={"little-title"}>*/}
                            {/*{Mes.appName}*/}
                        {/*</span>*/}
                        <span className={"little-title"}>
                            {this.timestampToTime(Mes.time)}
                        </span>
                        <div className={"content"}>
                            <div dangerouslySetInnerHTML={{ __html: lcontent }}>
                            </div>
                        </div>
                    </div>
                </WingBlank>
                :null

        )
    }
}
export default msgDetail
