import React from 'react'
import { NavBar ,Icon, Toast} from 'antd-mobile'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux"
import SetImg from "./setting.png"
import AddImg from "./add.png"
import FeedbackImg from "./feedback.png"
import {rssThisApp} from "../../redux/detail.redux"
import {sendFeedback,setFeedback} from "../../redux/feedback.redux"
import "./header.css"
import swal from "sweetalert"
import {FETCH} from "../../util/fetch";
import {ChangeRssUrl} from "../../api/serviceConfig";

@withRouter
@connect(
    state=>state,
    {rssThisApp,sendFeedback,setFeedback}
)
class Header extends React.Component {
    //检查此时编辑的参数信息是否与已经订阅的参数重复
    checkParameters(parameters,myParams){
        let ifAlready = false;
        let alreadyParams = myParams.filter(v => {
            return this.sameParameters(parameters,v.parameters) === true
        })
        if (alreadyParams.length > 0) {
            ifAlready = true
        }
        return ifAlready;
    }

    sameParameters (parameters1,parameters2) {
        if (parameters1.length === 0 && parameters2.leading === 0) {
            return true
        }
        for (let i=0; i<parameters1.length; i++ ){
            console.log(parameters1,parameters1[i],parameters1[i]["key"],parameters2)
            console.log(parameters2.filter(v => {return v["key"] === parameters1[i]["key"]}))
            let para2 = parameters2.filter(v => {return v["key"] === parameters1[i]["key"]})
            if (para2.length>0 && para2[0]["value"] !== parameters1[i]["value"]) {
                return false
            }
        }
        return true
    }

    //检查parameters中是否存在参数值为空的情况
    // hasEmptyPara(parameters){
    //     for (let para of parameters) {
    //         if (para["value"]===''){
    //             return true
    //         }
    //     }
    //     return false
    // }

    render() {
        const deviceId = window.localStorage.getItem("deviceId")
        const myParams = this.props.detail.detail.userParams.filter(v=>{return v.deviceId === deviceId})
        const pathname = this.props.location.pathname
        if(pathname === '/myservice') {
            return (
                <NavBar
                    mode="dark"
                    leftContent={[
                        <img  className={`am-icon`} src={FeedbackImg}
                              key={"0"}
                            onClick={()=>{
                                this.props.setFeedback({
                                    title:'',
                                    content:''
                                })
                                this.props.history.push('/feedback:phone=' + window.localStorage.getItem("phone"))
                            }}
                        />
                    ]}
                    rightContent={[
                        <img  className={`am-icon`} src={AddImg}
                              key={"1"}
                              onClick={()=>{this.props.history.push('/addservice')}}
                        />
                    ]}
                >我的订阅</NavBar>
            )
        } else if (pathname === '/addservice') {
            return (
                <NavBar
                    leftContent={[<Icon type="left" key={"2"}
                        onClick={()=>{
                            this.props.history.push("/myservice")
                        }}
                    />]}
                    mode="dark"
                >添加订阅</NavBar>
            )
        }else if(pathname === '/firstUse'){

            return (
                <NavBar
                    mode="dark"
                >欢迎使用</NavBar>
            )
        } else if( /\/detail\/*/.test(pathname) ){
            return (
                <NavBar
                    mode="dark"
                    leftContent={[
                        <Icon key={"0"} type={"left"}

                                    onClick={()=>{
                                        if (this.props.detail.isEditRss) {
                                            this.props.history.push('/myservice')
                                        } else if(!window.localStorage.getItem("isNewUser")) {
                                            this.props.history.push('/addservice')
                                        } else {
                                            this.props.history.push('/firstUse')
                                        }
                                    }}
                        ></Icon>
                    ]}
                    rightContent={[
                        <span
                            key={"1"}
                            onClick={()=>{
                                if (this.checkParameters(this.props.detail.userParams.parameters,myParams)){
                                    Toast.fail('不可重复订阅')
                                }
                                else {
                                    if (this.props.detail.isEditRss) {
                                        swal({
                                            title:'修改配置',
                                            text: "消息将被清空，是否继续？",
                                            // icon: "warning",
                                            buttons:["关闭","确认"],
                                            buttons:{
                                                cancel: "关闭",
                                                confirm: "确认",
                                                roll: {
                                                    text: "",
                                                    value: "roll",
                                                },
                                            },
                                            // dangerMode: true,
                                            closeOnConfirm: false,
                                            closeOnCancel: false
                                        }).then((willEdit)=>{
                                            if (willEdit){
                                                console.log('Edit')
                                                if (!this.props.detail.isRssSuccess) {
                                                    this.props.rssThisApp("POST")
                                                }
                                            }
                                        })
                                    } else {
                                        if (!this.props.detail.isRssSuccess) {
                                            this.props.rssThisApp("POST")
                                        }
                                    }

                                }
                            }}
                        > {this.props.detail.isEditRss?'保存配置':'订阅服务'}</span>
                    ]}
                >
                    {/*{pathname.match( /\/detail:(\S*)/)[1]}*/}
                    </NavBar>
            )
        } else if (pathname === '/setting' ) {
            return (
                <NavBar
                    mode="dark"
                >我的</NavBar>
            )
        } else if( /\/historyMes\/*/.test(pathname) ){
            return (<NavBar
                mode="dark"
                leftContent={[
                    <Icon key={"0"} type={"left"}
                          onClick={()=>{
                              this.props.history.push('/myservice')
                          }}
                    ></Icon>
                ]}
                rightContent={
                    [
                        <Icon key={"0"} type={"ellipsis"}
                              onClick={()=>{
                                  this.props.history.push('/setSubscription:'+pathname.match( /\/historyMes:(\S*)/)[1])
                              }}
                        ></Icon>
                    ]
                }
            >{pathname.match( /\/historyMes:(\S*)/)[1]}
            </NavBar>)
        } else if( /\/msgDetail:id=\/*/.test(pathname) ){
            return (<NavBar
                mode="dark"
                leftContent={[
                    <Icon key={"0"} type={"left"}
                          onClick={()=>{
                              this.props.history.push('/historyMes:'+ pathname.match(/appName=(\S*)/)[1])
                          }}
                    ></Icon>
                ]}
            >
                {/*{pathname.match( /appName=(\S*)/)[1]}*/}
                {"消息详情"}
            </NavBar>)
        } else if (/\/feedback:phone=\/*/.test(pathname)) {
            return (<NavBar
                mode="dark"
                leftContent={[
                    <Icon key={"0"} type={"left"}
                          onClick={()=>{
                              // this.props.history.push('/addservice')
                              this.props.history.go(-1)
                          }}
                    ></Icon>
                ]}
                rightContent={[
                    <span
                        key={"1"}
                        onClick={()=>{
                            let phone = this.props.location.pathname.match( /\/feedback:phone=(\S*)/)[1]
                            let deviceId = window.localStorage.getItem("deviceId")
                            this.props.sendFeedback(phone,deviceId).then(v=>{
                                console.log(v)
                                if (v){
                                    setTimeout(()=>{
                                        this.props.history.go(-1)
                                    },1000)
                                }
                            })
                        }}
                    > {'提交反馈'}</span>
                ]}
            >
                {/*{pathname.match( /appName=(\S*)/)[1]}*/}
                {""}
            </NavBar>)
        }else if( /\/setSubscription\/*/.test(pathname) ){
            return (<NavBar
                mode="dark"
                leftContent={[
                    <Icon key={"0"} type={"left"}
                          onClick={()=>{
                              this.props.history.go(-1)
                          }}
                    ></Icon>
                ]}
            >
                {'订阅详情'}
            </NavBar>)
        } else {
            return null
        }
    }
}
export default Header
