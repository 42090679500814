import React from 'react'
import ReactDom from 'react-dom'
import {createStore,applyMiddleware,compose} from 'redux'
import thunk from 'redux-thunk'
import {Provider} from 'react-redux'
import {Route,Switch,HashRouter} from 'react-router-dom'

import "./index.css"
import reducers from './redux/reducer'
import Login from './container/login/login'
import Home from './container/home/home'

// 用作手机调试
//  import eruda from "eruda"
//  eruda.init()



const store = createStore(reducers,compose(applyMiddleware(thunk),
    window.devToolsExtension?window.devToolsExtension() : f => f
))
ReactDom.render(
    (
        <Provider store={store}>
            <HashRouter>
                <div>
                    <Switch>
                        <Route path="/login" component={Login}></Route>
                        <Route path="/detail:id" component={Home}></Route>
                        <Route path="/historyMes:id" component={Home}></Route>
                        <Route path="/msgDetail:id=:id" component={Home}></Route>
                        <Route path="/setSubscription:id" component={Home}></Route>
                        <Route path="/feedback:phone=:phone" component={Home}></Route>
                        <Route path="/(addservice|myservice|setting|firstUse)/" component={Home}></Route>
                        <Route path="/" component={Login}></Route>
                    </Switch>
                </div>
            </HashRouter>
        </Provider>
    ),
    document.getElementById('root')
)

