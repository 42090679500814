import React from 'react'
import { List ,SwipeAction,NoticeBar,Icon,SearchBar ,WhiteSpace,ListView} from 'antd-mobile'
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"

import {FETCH} from "../../util/fetch"
import EditImg from "../myService/edit.png"
import DelImg from "../myService/del.png"
import {getMyMes,hasReadMes,delMes} from "../../redux/historyMes.redux"
import {badAlert} from "../badAlert/badAlert";

import noticeImg from "./notice.png"

@withRouter
@connect(
    state=>state.historyMes,
    {getMyMes,hasReadMes,delMes}
)
class HistoryMes extends React.Component {
    constructor(props) {
        super(props)
        this.timestampToTime = this.timestampToTime.bind(this)
    }
    componentDidMount(){
        if (document.getElementsByClassName('am-list')[0]) {
            document.getElementsByClassName('am-list')[0].parentNode.parentNode
                .style = "overflow-y: scroll;height:"+(window.innerHeight-45).toString()+"px"
        }
    }

    timestampToTime(timestamp) {
        var date = new Date(parseInt(timestamp));//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
        let D = date.getDate() <10 ? '0'+ date.getDate()+' ': date.getDate()+' ';
        let h = date.getHours() <10 ? '0' + date.getHours() + ':': date.getHours() + ':';
        let m = date.getMinutes()<10 ? '0' + date.getMinutes() : date.getMinutes();
        // let s = date.getSeconds();
        return Y+M+D+h+m;
    }
    //判断两个parameter对象值是否相同
    diff(obj1,obj2){
        var o1 = obj1 instanceof Object;
        var o2 = obj2 instanceof Object;
        if(!o1 || !o2){/*  判断不是对象  */
            return obj1 === obj2;
        }

        if (obj1.key === obj2.key && obj1.value === obj2.value) return true;

        return false
    }

//判断两个parameters数组是否相同
    arrDiff(arr1,arr2) {
        if (arr1.length===0 && arr2.length===0) {
            return true
        } else if (arr1.length!==arr2.length){
            return false
        } else {
            for (let i =0; i<arr1.length; i++){
                if (!this.diff(arr1[i],arr2[i])){
                    return false
                }
            }
            return true
        }

    }
    render() {
        const appName = this.props.location.pathname.match( /\/historyMes:(\S*)/)[1]
        const Mes = this.props.message.filter(v => {return v.appName === appName && this.arrDiff(v.parameters,this.props.parameters)}).reverse()
        const availableMes = Mes.filter(v => {return !v.isDeleted})
        return (
            <div>
                {/*<SearchBar placeholder="搜索" maxLength={8} />*/}
                <div style={{position:'fixed',top:'45px',zIndex:'100',width:'100%'}}>
                    <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}  icon={null}>
                        {this.props.descriptions}
                    </NoticeBar>
                </div>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                <WhiteSpace/>
                {
                    availableMes.length >0 ?
                        <List>
                            {
                                availableMes.length > 0 ?
                                    availableMes.map((v,index)=>(
                                        !v.isDeleted &&
                                        <SwipeAction
                                            key = {index}
                                            style={{ backgroundColor: 'white' }}
                                            autoClose
                                            right={[
                                                {
                                                    text: <span style={{width:"50px"}}
                                                                onClick={()=>{
                                                                    this.props.hasReadMes(v._id)
                                                                }}
                                                    >已读</span>,
                                                    style: { backgroundColor: '#777777', color: 'white' },
                                                },
                                                {
                                                    text: <span style={{width:"50px"}}>删除</span>,
                                                    onPress: () => {
                                                        this.props.delMes(v._id)
                                                    },
                                                    style: { backgroundColor: 'red', color: 'white'},
                                                }
                                            ]}
                                            left={[

                                            ]}
                                            onOpen={() => {}}
                                            onClose={() => {}}
                                        >
                                            <List.Item
                                                onClick={()=>{
                                                    // badAlert(v.content,undefined,'确定',0)
                                                    this.props.hasReadMes(v._id)
                                                    this.props.history.push('/msgDetail:id='+v._id+'&appName='+v.appName)

                                                }}
                                                multipleLine
                                                badge={1}
                                            >
                                        <span style={{
                                            color:v.hasRead ? "#888888":"#fd5f00",
                                        }}>{this.timestampToTime(v.time)}</span> <List.Item.Brief>{v.content}</List.Item.Brief>
                                            </List.Item>
                                        </SwipeAction>
                                    ))
                                    :
                                    null
                            }
                        </List>
                        :
                        <div style={{height:'100%',textAlign:'center',marginTop:window.innerHeight/3}}>
                            <img src={noticeImg} alt={'暂无消息通知'} height={'78px'} width={'78px'}/>
                            <p style={{color:'#aaaaaa'}}>暂无通知消息</p>
                        </div>
                }
            </div>
        )
    }
}
export default HistoryMes
