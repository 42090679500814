import {Toast} from "antd-mobile"
//
// let reqMap = new Map()

function _fetch_ (input, init = {}) {
    //拦截请求，在请求之前加上本地缓存的token
    // let reqKey = `${new Date().getTime()}${Math.floor(Math.random() * (100 - 0))}`
    if(window.localStorage.getItem("token")) {
        // if (window.location.href.indexOf('/myservice') < 0) Toast.loading('Loading...',0.5)
        //设置超时时间
        const token  = window.localStorage.getItem("token")
        if(init.headers) {
            init.headers.token = token
        } else {
            init.headers = {token}
        }
    }

    return new Promise(function (resolve, reject) {
        fetch(input, init).then(res => {
            // console.log(new Date().getTime(),"reqMap setTimeOut delete")
            resolve(res)
            setTimeout(()=>{
                if (window.location.href.indexOf('/detail') < 0 && window.location.href.indexOf('feedback') < 0) Toast.hide()
            },2000) //10改为了2000,加上判断，如果在详情页（订阅执行页）或者消息反馈页则不关闭提示框
        }).catch(err => {
            Toast.hide()
            Toast.fail('网络请求缓慢',1)
            // clearTimeout(reqMap.get(reqKey))
            // reqMap.delete(reqKey)
            // console.log(new Date().getTime(),"reqMap setTimeOut delete")
            reject(err);
        })
    })
}

//让fetch支持超时
function _fetch(fetch_promise, timeout) {
    var abort_fn = null;

    //这是一个可以被reject的promise
    var abort_promise = new Promise(function(resolve, reject) {
        abort_fn = function() {
            reject('abort promise');
        };
    });

    //这里使用Promise.race，以最快 resolve 或 reject 的结果来传入后续绑定的回调
    var abortable_promise = Promise.race([
        fetch_promise,
        abort_promise
    ]);

    setTimeout(function() {
        abort_fn();
    }, timeout);

    return abortable_promise;
}

export function FETCH(input, init = {}) {
    //return _fetch(_fetch_(input,init),4000)
    return _fetch_(input,init)
}
