import React from 'react'
import {Button} from "antd-mobile"

import "./home.css"
import NavLinkBar from '../../component/navlink/navlink'
import Header from '../../component/header/header'
import ServiceList from '../../component/servicelist/servicelist'
import MyService from "../../component/myService/myService"
import Setting from "../../component/setting/setting"
import Detail from "../../component/detail/detail"
import AutoRoute from "../../component/autoRoute/autoRoute"
import DisableBack from "../../component/disableBack/disableBack"
import HistoryMes from "../../component/historyMes/historyMes"
import MsgDetail from "../../component/msgDetail/msgDetail"
import Feedback from "../../component/feedback/feedback"
import SetSubscription from "../../component/setSubscription/setSubscription"


class Home extends React.Component {
    componentWillMount(){
        this.setState({
            Height:window.innerHeight*(window.innerHeight*2/10000+0.72)
        })
        setTimeout(()=>{
            this.setState({
                Height:window.innerHeight*(window.innerHeight*2/10000+0.72)
            })
        },1000)
    }
    render(){
        const pathname = this.props.location.pathname
        return(
            <div>
                <DisableBack/>
                <AutoRoute/>
                <Header/>
                <div
                    style={{
                        "overflowY": "scroll",
                        "height": `${this.state.Height}px`
                    }}
                >
                    {
                        pathname.indexOf('/addservice')>=0  && <ServiceList/>
                    }
                    {
                        pathname === '/firstUse' && <div>
                            <div style={{ backgroundColor: 'white', height: '100%', textAlign: 'center' }}>
                                <div style={{ paddingTop: 2 }}></div>
                                <span style={{ display: 'block', marginTop: 15, marginBottom: 5,fontSize:"14px" }}>
                                    请选择您要订阅的应用 &nbsp;&nbsp;
                                    <span style={{color:"blue"}} onClick={()=>{
                                        window.localStorage.setItem("isNewUser","")
                                        this.props.history.push("/myservice")}
                                    }>跳过></span>
                                </span>
                            </div>
                            <ServiceList/>
                        </div>
                    }
                    {
                        pathname.indexOf('/myservice')>=0  && <MyService/>
                    }
                    {
                        pathname === '/setting' && <Setting/>
                    }
                    {
                        /\/detail\/*/.test(this.props.location.pathname)
                            ? <Detail/>
                            : null
                    }
                    {
                        /\/historyMes\/*/.test(this.props.location.pathname)
                            ? <HistoryMes/>
                            : null
                    }
                    {
                        /\/msgDetail:id=\/*/.test(this.props.location.pathname)
                            ? <MsgDetail/>
                            : null
                    }
                    {
                        /\/feedback:phone=\/*/.test(this.props.location.pathname)
                            ? <Feedback/>
                            : null
                    }
                    {
                        /\/setSubscription\/*/.test(this.props.location.pathname)
                            ? <SetSubscription/>
                            : null
                    }
                </div>
                {
                    (pathname.indexOf('/myservice')>=0 || pathname === '/setting') && <NavLinkBar/>
                }
            </div>
        )
    }
}

export default Home
