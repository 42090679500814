import React from 'react'
import {TabBar} from 'antd-mobile'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux"

import "./navlink.css"


@withRouter
@connect(
    state=>state.historyMes
)
class NavLinkBar extends React.Component{
    render(){
        const pathname = this.props.location.pathname
        const deviceId = window.localStorage.getItem("deviceId")
        return (
            deviceId && deviceId.indexOf('openid')!==0?
            <TabBar
                unselectedTintColor="#949494"
                tintColor="#33A3F4"
                barTintColor="white"
                tabBarPosition='bottom'
            >
                <TabBar.Item
                    // badge={v.path === '/msg' ? this.props.unread :0}
                    key={0}
                    title={"订阅"}
                    badge={(()=>{
                        let a = 0
                        // console.debug(this.props)
                        if(this.props.message&&this.props.message.length > 0) {
                            this.props.message.forEach(v=>{
                                if(!v.hasRead && !v.isDeleted
                                    && v.content !=='' //若消息为空则不计数
                                ) {
                                    a++
                                }
                            })
                        }
                        return a
                    })()}
                    icon={{uri:require(`./dingyue0.png`)}}
                    selectedIcon={{uri:require(`./dingyue1.png`)}}
                    selected={pathname==="/myservice"}
                    onPress={()=>{
                        this.props.history.push('/myservice')
                    }}
                >
                </TabBar.Item>
                <TabBar.Item
                key={0}
                title={"我的"}
                icon={{uri:require(`./mine0.png`)}}
                selectedIcon={{uri:require(`./mine1.png`)}}
                selected={pathname==="/setting"}
                onPress={()=>{
                    this.props.history.push('/setting')
                }}
            >
            </TabBar.Item>
            </TabBar>
                :null
        )
    }
}
export default  NavLinkBar
