import React from 'react'
import { Grid,SearchBar, NoticeBar,WhiteSpace } from 'antd-mobile'
import {withRouter} from 'react-router-dom'
import './servicelist.css'
import {connect} from "react-redux"
import {GetMyOpenid, GetServiceList} from "../../api/serviceConfig"
import {FETCH} from "../../util/fetch"
import CheckUpdate from "../checkUpdate/checkUpdate"
import {setParameters} from "../../redux/historyMes.redux"
import {setDetailParameters} from "../../redux/detail.redux";
import {setFeedback} from "../../redux/feedback.redux"
import axios from "axios/index";

@withRouter
@connect(
    state=>({
        historyMes:state.historyMes,
        detail:state.detail
    }),
    {setParameters,setDetailParameters,setFeedback}
)
class ServiceList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initApps:[],
            apps:[],
            initGridData:[],
            gridData:[]
        }
    }

    componentWillMount(){
        if (window.location.href.indexOf('?code=')>=0) {
            let index1 = window.location.href.indexOf('?code=')+6
            let index2 = window.location.href.indexOf('&state')
            let code = window.location.href.substring(index1,index2)
            axios(GetMyOpenid+`?code=${code}`)
                .then(data => {
                    if(data.data) {
                        window.localStorage.setItem("phone","openid"+data.data)
                        window.localStorage.setItem("deviceId","openid"+data.data)
                    } else {
                        console.log("error")
                    }
                })
                .catch(error=>{
                    console.log(error)
                })

        }
    }

    componentDidMount() {
        this.props.setDetailParameters([])
        this.props.setParameters([])
        FETCH(GetServiceList)
            .then(res => {
                res.json().then(data=>{
                    // console.log(data)
                    if(data.code === 0 ){
                        let gridData = []
                        data.data.apps.forEach(v=>{
                            if (!v.isDeleted){
                                gridData.push({
                                    icon:v.icon,
                                    text:v.appName
                                })
                            }
                        })
                        this.setState({
                            initApps:data.data.apps.filter(v=>{return !v.isDeleted}),
                            apps:data.data.apps.filter(v=>{return !v.isDeleted}),
                            initGridData:gridData,
                            gridData:gridData
                        })
                    }
                })
            })
            .catch(err => {
                //
                //alert(err)
            })
    }
    render() {
        return (
            <div>
                <SearchBar placeholder="搜索" maxLength={8}
                           onSubmit = {v =>{
                               let apps = []
                               let gridData = []
                               if (v !== ''){
                                   for (let i=0; i<this.state.initApps.length; i++){
                                       if (this.state.initApps[i].appName.indexOf(v) >= 0) {
                                           apps.push(this.state.initApps[i])
                                           gridData.push({
                                               icon:this.state.initApps[i].icon,
                                               text:this.state.initApps[i].appName
                                           })
                                       }
                                   }
                                   this.setState({
                                       apps:apps,
                                       gridData:gridData
                                   })
                               } else {
                                   this.setState({
                                       apps:this.state.initApps,
                                       gridData:this.state.initGridData
                                   })
                               }
                           }}
                           onCancel = {()=>{
                               this.setState({
                                   apps:this.state.initApps,
                                   gridData:this.state.initGridData
                               })
                           }}
                           onChange = {v =>{
                               let apps = []
                               let gridData = []
                               if (v !==''){
                                   for (let i=0; i<this.state.initApps.length; i++){
                                       if (this.state.initApps[i].appName.indexOf(v) >= 0){
                                           apps.push(this.state.initApps[i])
                                           gridData.push({
                                               icon:this.state.initApps[i].icon,
                                               text:this.state.initApps[i].appName
                                           })
                                       }
                                   }
                                   this.setState({
                                       apps:apps,
                                       gridData:gridData
                                   })
                               } else {
                                   this.setState({
                                       apps:this.state.initApps,
                                       gridData:this.state.initGridData
                                   })
                               }
                           }}
                />
                {/*this.state.apps.length > 0 ?*/}
                <Grid
                    data={this.state.gridData}
                    hasLine={false}
                    onClick={(el,index)=>{
                        this.props.history.push(`/detail:${this.state.gridData[index].text}`)
                    }}
                    itemStyle={{background: 'rgba(0, 0, 0, 0)' }}
                />
                <WhiteSpace/>
                <NoticeBar mode="link"  onClick={() => {
                    this.props.setFeedback({
                        title:'',
                        content:''
                    })
                    this.props.history.push('/feedback:phone=' + window.localStorage.getItem("phone"))
                }}>
                    没有想要的应用? 点击这里反馈给我们
                </NoticeBar>
                {/*:*/}
                {/*null*/}
            </div>
        )
    }
}
export default ServiceList
