import React from 'react'
import {Modal} from "antd-mobile"

import {FETCH} from "../../util/fetch"
import {CheckUpdateUrl} from "../../api/serviceConfig"
import {App} from "../../config/config"



class CheckUpdate extends React.Component {
    componentDidMount() {
        let lastNotUpdateTime = window.localStorage.getItem("lastNotUpdateTime")
        if(lastNotUpdateTime) {
            let nowTime = new Date().getTime()
            if( nowTime - parseInt(lastNotUpdateTime) < 24 * 7 * 3600 * 1000 ) {
                //距离上一次取消时间在一周内
                return
            }
        }
        FETCH(CheckUpdateUrl,{method:"POST"})
            .then(res => {
                res.json().then(data => {
                    if(data.data.version !== App.version) {
                        try {
                            Modal.alert('','检测到有新版本，是否立刻升级？', [
                                { text: '取消', onPress: () => {
                                        //记录这次取消的时间，再隔一周检测
                                        window.localStorage.setItem("lastNotUpdateTime",`${new Date().getTime()}`)
                                    } },
                                { text: '确定', onPress: () => {
                                        window.localStorage.setItem("lastNotUpdateTime","")
                                        window.open(data.data.apkUrl, '_system', 'location=yes')
                                    } },
                            ])
                        } catch(e) {
                            console.error("failed : ",e)
                        }
                    }
                })
            })
            .catch(e => {
                //
            })
    }
    render() {
        return null
    }
}

export default CheckUpdate
