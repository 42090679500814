import {FETCH} from "../util/fetch"
import {GetServiceDetail, ChangeRssUrl, GetThisAllowedOrNot} from "../api/serviceConfig"
import {Toast} from "antd-mobile"


const initState = {
    isRssSuccess:false,
    isEditRss:false,
    detail:{
        appName:"",
        description:"",
        developer:"",
        totalRssCount:0,
        paramsDescription:[],
        userParams:[]
    },
    userParams:{
        deviceId:"",
        parameters:[],
        allowNotice:true,
        paramsDescription:[]
    }
}

const INIT = "INIT"
const SETVALUE = "SETVALUE"
const RSSSUCCESS = "RSSSUCCESS"

export function detail(state=initState,action) {
    switch(action.type) {
        case INIT :
        case SETVALUE:
        case RSSSUCCESS:
            return action.payload
        default:
            return state
    }
}

export function initDetail() {
    return async(dispatch,getState) =>{
        let detail = getState().detail
        dispatch({
            type:SETVALUE,
            payload:{
                isRssSuccess:false,
                isEditRss:false,
                detail:detail.detail,
                userParams:detail.userParams
            }
        })
    }
}

export function setDetailParameters(parameters) {
    return async(dispatch,getState) =>{
        let detail = getState().detail
        let userParams = detail.userParams
        userParams.parameters = parameters
        dispatch({
            type:SETVALUE,
            payload:{
                isRssSuccess:detail.isRssSuccess,
                isEditRss:parameters.length>0 ,
                detail:detail.detail,
                userParams:userParams
            }
        })
    }
}

export function useThisApp(appName) {
    return async(dispatch,getState) => {
        FETCH(GetServiceDetail+`?appName=${appName}&phone=${window.localStorage.getItem("phone")}`)
            .then(res => {
                res.json().then(data => {
                    if(data.code === 0) {
                        let parameters = []
                        if (getState().detail.userParams.parameters.length > 0) {
                            parameters = getState().detail.userParams.parameters
                        } else if(data.data.detail.paramsDescription.length > 0 ) {
                            for(let i in data.data.detail.paramsDescription) {
                                parameters.push({key:data.data.detail.paramsDescription[i].name,value:""})
                            }
                        }
                        const {appName,description,developer,icon,totalRssCount,paramsDescription,userParams} = data.data.detail
                        const deviceId = window.localStorage.deviceId
                        const isEditRss = getState().detail.isEditRss
                        FETCH(GetThisAllowedOrNot,{
                            method:"POST",
                            mode:"cors",
                            headers: {
                                "Content-Type": "application/json"
                            },
                            body:JSON.stringify({
                                appName:appName,
                                deviceId:deviceId,
                                parameters:parameters
                            })
                        }).then(res=>{
                            res.json().then(data=>{
                                dispatch({
                                    type:SETVALUE,
                                    payload:{
                                        isRssSuccess:false,
                                        isEditRss:isEditRss,
                                        detail:{appName,description,developer,icon,totalRssCount,paramsDescription,userParams},
                                        userParams:{
                                            deviceId:window.localStorage.deviceId,
                                            parameters:parameters,
                                            allowNotice:isEditRss? data.data.allowNotice : true,
                                            paramsDescription:paramsDescription
                                        }
                                    }
                                })
                            })
                        }).catch(err=>{
                            Toast.fail('网络错误，稍后再试',1)
                        })

                    } else {
                        Toast.info('出错了',1)
                    }
                })
            })
            .catch(err=>{
                Toast.info('出错了',1)
            })
    }
}

export function setAppValue(key,val) {
    return async(dispatch,getState) => {
        const detail = getState().detail
        const userParams = detail.userParams
        const m = []
        let ans = 0
        for(let l = 0; l <  userParams.parameters.length ;l++) {
            m.push()
            if(userParams.parameters[l].key === key) {
                ans = l
                m.push({
                    key:userParams.parameters[l].key,
                    value:val
                })
            } else {
                m.push(userParams.parameters[l])
            }
        }
        dispatch({
            type:SETVALUE,
            payload:{
                isRssSuccess:false,
                isEditRss:detail.isEditRss,
                detail:detail.detail,
                userParams:{
                    deviceId:detail.userParams.deviceId,
                    parameters:m,
                    allowNotice:detail.userParams.allowNotice,
                    paramsDescription:detail.userParams.paramsDescription
                }
            }
        })
    }
}

//订阅或取消订阅，订阅method为POST,取消method为DELETE
export function rssThisApp(method) {
    return async(dispatch,getState) => {
        const detail = getState().detail
        const historyMes = getState().historyMes
        let ans = true
        let a = false
        for(let b of detail.userParams.parameters) {
            if(!b.value) {
                for(let c of detail.userParams.paramsDescription) {
                    if(c.name === b.key && c.isRequired) {
                        a = true
                    }
                }
            }
        }

        if(a) {
            Toast.fail("输入信息不完整",1)
            return
        }
        if(window.localStorage.getItem("isNewUser")) {
            window.localStorage.setItem("isNewUser","")
        }
        FETCH(ChangeRssUrl,{
            method:method,
            mode:"cors",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                appName:detail.detail.appName,
                phone:window.localStorage.getItem("phone"),
                deviceId:window.localStorage.getItem("deviceId"),
                userParams:detail.userParams,
                isEditRss:detail.isEditRss,
                oldParameters:historyMes.parameters?historyMes.parameters:[]
            })
        })
            .then(res => {
                res.json().then(data=>{
                    console.log(data,method,data.code)
                    if(data.code === 0) {
                        if(method === "POST") {
                            console.log('method is post')
                            setTimeout(()=>{
                                dispatch({
                                    type:RSSSUCCESS,
                                    payload:{
                                        isRssSuccess:true,
                                        isEditRss:detail.isEditRss,
                                        detail:detail.detail,
                                        userParams:detail.userParams
                                    }
                                })
                            },1000)   //设置1秒钟后执行改变isRssSuccess值，则订阅成功后会停留1秒钟才跳转

                            // console.log('success')
                            Toast.success(detail.isEditRss?"编辑成功":"订阅成功",2)
                        }
                        else Toast.success("退订成功",1)
                    } else {
                        Toast.fail("操作失败",1)
                    }
                })
            })
            .catch(err=>{
            })
    }
}
