import React from 'react'
import {withRouter} from 'react-router-dom'

@withRouter
class AutoRoute extends React.Component {
    componentWillMount() {
        if(!window.localStorage.getItem("token") && window.location.href.indexOf('?code')<0
        && window.localStorage.getItem("deviceId").indexOf('openid')!==0) {
            this.props.history.push("/login")
        }
    }
    render() {
        return null
    }
}

export default AutoRoute
