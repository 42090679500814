import React from 'react'
import { List,Button, WingBlank, WhiteSpace ,Toast} from 'antd-mobile'
import {withRouter} from 'react-router-dom'

import logoImg from "./logo.png"
import "./setting.css"
import EditImg from "./edit.png"
import swal from "sweetalert"
@withRouter
class Setting extends React.Component {
    render() {
        return (
            <List>
                <div className="logo-container">
                    <img src={logoImg} alt=""/>
                    <div style={{marginTop:"0px"}}>
                        <span style={{color:"white",marginRight:"10px",fontSize:"18px"}}>{window.localStorage.getItem("phone")}</span><img
                            onClick={() => {
                                Toast.fail("暂不支持此功能",1)
                            }}
                        src={EditImg}
                    />
                    </div>
                </div>
                <List.Item
                    onClick={() => {
                        Toast.fail("暂不支持此功能",1)
                    }}
                    arrow="horizontal"
                >修改绑定手机</List.Item>
                <List.Item
                    arrow="horizontal"
                    onClick={()=>{
                       swal({
                           title:"退出登录",
                           text: "是否确认退出登录",
                           // icon: "warning",
                           buttons:["关闭","确认"],
                           buttons:{
                               cancel: "关闭",
                               confirm: "确认",
                               roll: {
                                   text: "",
                                   value: "roll",
                               },
                           },
                           // dangerMode: true,
                           closeOnConfirm: false,
                           closeOnCancel: false
                       })
                           .then((logout)=>{
                               if (logout) {
                                   if(window.sessionStorage) {
                                       window.sessionStorage.clear()
                                       window.localStorage.setItem("token","")
                                   }
                                   this.props.history.push('/login')
                               }
                           })
                    }}
                >退出登录</List.Item>
            </List>
        )
    }
}
export default Setting
