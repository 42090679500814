import React from 'react'
import { List ,SwipeAction ,SearchBar ,WhiteSpace,ListView} from 'antd-mobile'
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"

import {AppRootUrl, ChangeRssUrl,GetMyOpenid} from "../../api/serviceConfig"
import {Toast,Badge} from "antd-mobile";
import {FETCH} from "../../util/fetch"
import EditImg from "./edit.png"
import DelImg from "./del.png"
import {getMyMes,delMes,setParameters} from "../../redux/historyMes.redux"
import {initDetail,setDetailParameters} from "../../redux/detail.redux";
import "./myService.css"
import swal from "sweetalert"
import axios from "axios"
@withRouter
@connect(
    state=>({
        historyMes:state.historyMes,
        detail:state.detail
    }),
    {getMyMes,initDetail,delMes,setParameters,setDetailParameters}
)
class MyService extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            initApps:[],
            apps:[],
            listArr:[],
            searchValue:'',
            msgsId:[]
        }
        this.getMyServices = this.getMyServices.bind(this)
    }

    componentWillMount(){
        if (window.location.href.indexOf('?code=')>=0) {
            let index1 = window.location.href.indexOf('?code=')+6
            let index2 = window.location.href.indexOf('&state')
            let code = window.location.href.substring(index1,index2)
            axios(GetMyOpenid+`?code=${code}`)
                .then(data => {
                    if(data.data) {
                            window.localStorage.setItem("phone","openid"+data.data)
                            window.localStorage.setItem("deviceId","openid"+data.data)
                    } else {
                            console.log("error")
                    }
                })
                .catch(error=>{
                    console.log(error)
                })

        }
    }
    componentDidMount() {
        document.getElementsByClassName('am-search')[0].parentNode.parentNode.style = "overflow-y: scroll;height:"+(window.innerHeight-95).toString()+"px"
        let interval = setInterval(()=>{
            if (window.location.href.indexOf('myservice')>=0){
                //获取我的服务列表
                this.getMyServices()
                //获取我的消息
                this.props.getMyMes()
            } else {
                clearInterval(interval)
            }

        },3000)
        let interval1 = setInterval(()=>{
            if (window.location.href.indexOf('myservice')>=0){
                //根据屏幕大小设置布局参数
                document.getElementsByClassName('am-search')[0].parentNode.parentNode.style = "overflow-y: scroll;height:"+(window.innerHeight-95).toString()+"px"

            }

        },500)
        //获取我的服务列表
        this.getMyServices()
        //获取我的消息
        this.props.getMyMes()
        this.props.initDetail()
    }
    getMyServices() {
        let that = this;
        FETCH(ChangeRssUrl+`?phone=${window.localStorage.getItem('phone')}`)
            .then(res => {
                res.json().then(data => {
                    if(data.code === 0) {
                        let arr =data.data.apps.filter(v=>{
                            return v.appName.indexOf(that.state.searchValue) >= 0
                        })
                        // for(let v of data.data.apps) {
                        //     if (v.appName.indexOf(that.state.searchValue)>=0) {
                        //         arr.push({
                        //             img:v.icon,
                        //             title:v.appName,
                        //             des:v.description
                        //         })
                        //     }
                        // }
                        this.setState({
                            initApps:data.data.apps,
                            apps:this.state.searchValue===''?data.data.apps:arr,
                            listArr:arr
                        })
                    } else {
                        //
                    }
                })
            })
            .catch(err=>{
                //
            })
    }

    //判断两个parameter对象值是否相同
    diff(obj1,obj2){
        var o1 = obj1 instanceof Object;
        var o2 = obj2 instanceof Object;
        if(!o1 || !o2){/*  判断不是对象  */
            return obj1 === obj2;
        }

        if (obj1.key === obj2.key && obj1.value === obj2.value) return true;

        return false
    }

//判断两个parameters数组是否相同
    arrDiff(arr1,arr2) {
        if (!arr1 && !arr2) {
            return true
        } else if (arr1.length===0 && arr2.length===0) {
            return true
        } else if (arr1.length!==arr2.length){
            return false
        } else {
            for (let i =0; i<arr1.length; i++){
                if (!this.diff(arr1[i],arr2[i])){
                    return false
                }
            }
            return true
        }

    }

    render() {
        return (
            <div>
                <SearchBar placeholder="搜索" maxLength={8}
                           onSubmit = {v =>{
                               this.setState({
                                   searchValue:v
                               })
                               let apps = []
                               if (v !== ''){
                                   for (let i=0; i<this.state.initApps.length; i++){
                                       if (this.state.initApps[i].appName.indexOf(v) >= 0) apps.push(this.state.initApps[i])
                                   }
                                   this.setState({
                                       apps:apps
                                   })
                               } else {
                                   this.setState({
                                       apps:this.state.initApps
                                   })
                               }
                           }}
                           onClear = {()=>{
                               this.setState({
                                   apps:this.state.initApps,
                                   searchValue:''
                               })
                           }}
                           // onCancel = {()=>{
                           //     this.setState({
                           //         apps:this.state.initApps,
                           //         searchValue:''
                           //     })
                           // }}
                           onChange = {v =>{
                               this.setState({
                                   searchValue:v
                               })
                               let apps = []
                               if (v !==''){
                                   for (let i=0; i<this.state.initApps.length; i++){
                                       if (this.state.initApps[i].appName.indexOf(v) >= 0) apps.push(this.state.initApps[i])
                                   }
                                   this.setState({
                                       apps:apps
                                   })
                               } else {
                                   this.setState({
                                       apps:this.state.initApps
                                   })
                               }
                           }}
                />
                <List>
                    {
                        this.state.apps.length > 0 ?
                            this.state.apps.map((v,index)=>(
                                <SwipeAction
                                    key = {index}
                                    style={{ backgroundColor: 'white' }}
                                    autoClose
                                    right={[
                                        v.isDeleted ? {text:' '}:{
                                            text: <span style={{width:"100px"}}
                                                        onClick={()=>{
                                                            this.props.history.push(`/detail:${v.appName}`)
                                                        }}
                                            >编辑订阅</span>,
                                            style: { backgroundColor: '#cccccc', color: 'white' },
                                        },
                                        {
                                            text: <span style={{width:"80px"}}>删除</span>,
                                            onPress: () => {
                                                swal({
                                                    title:"删除订阅",
                                                    text: "是否删除已订阅提醒",
                                                    // icon: "warning",
                                                    buttons:["关闭","确认"],
                                                    buttons:{
                                                        cancel: "关闭",
                                                        confirm: "确认",
                                                        roll: {
                                                            text: "",
                                                            value: "roll",
                                                        },
                                                    },
                                                    // dangerMode: true,
                                                    closeOnConfirm: false,
                                                    closeOnCancel: false
                                                }).then((willDelete)=>{
                                                        if (willDelete){
                                                            console.log('delete')
                                                            let that = this
                                                            FETCH(ChangeRssUrl,{
                                                                method:"DELETE",
                                                                mode:"cors",
                                                                headers: {
                                                                    "Content-Type": "application/json"
                                                                },
                                                                body:JSON.stringify({
                                                                    appName:v.appName,
                                                                    phone:window.localStorage.getItem("phone"),
                                                                    deviceId:window.localStorage.getItem("deviceId"),
                                                                    parameters:v.parameters,
                                                                    msgsId:this.state.msgsId
                                                                })
                                                            })
                                                                .then(res => {
                                                                    res.json().then(data=>{
                                                                        if(data.code === 0) {
                                                                            //
                                                                            // that.setState({
                                                                            //     checked: !that.state.checked
                                                                            // })
                                                                            //that.getMyServices()
                                                                            let arr = []
                                                                            that.state.apps.forEach((vv,ii) => {
                                                                                if(ii !== index) {
                                                                                    arr.push(vv)
                                                                                }
                                                                            })
                                                                            this.setState({
                                                                                apps:arr
                                                                            })
                                                                            Toast.success('删除成功',1)
                                                                        } else if (data.mes==='APP已经下架了') {
                                                                            let arr = []
                                                                            that.state.apps.forEach((vv,ii) => {
                                                                                if(ii !== index) {
                                                                                    arr.push(vv)
                                                                                }
                                                                            })
                                                                            this.setState({
                                                                                apps:arr
                                                                            })
                                                                            Toast.success("删除成功",1)
                                                                        } else {
                                                                            Toast.fail("操作失败",1)
                                                                        }
                                                                    })
                                                                })
                                                                .catch(err=>{
                                                                    //
                                                                })
                                                        }
                                                    })
                                            },
                                            style: { backgroundColor: '#ff625d', color: 'white'},
                                        }
                                    ]}
                                    // left={[
                                    //     {
                                    //         text: <span style={{width:"50px"}}><img style={{maxWidth:"20px"}} src={DelImg}></img></span>,
                                    //         onPress: () => {
                                    //             console.log('delete')
                                    //             let that = this
                                    //             FETCH(ChangeRssUrl,{
                                    //                 method:"DELETE",
                                    //                 mode:"cors",
                                    //                 headers: {
                                    //                     "Content-Type": "application/json"
                                    //                 },
                                    //                 body:JSON.stringify({
                                    //                     appName:v.appName,
                                    //                     phone:window.localStorage.getItem("phone"),
                                    //                     deviceId:window.localStorage.getItem("deviceId")
                                    //                 })
                                    //             })
                                    //                 .then(res => {
                                    //                     res.json().then(data=>{
                                    //                         if(data.code === 0) {
                                    //                             //
                                    //                             // that.setState({
                                    //                             //     checked: !that.state.checked
                                    //                             // })
                                    //                             //that.getMyServices()
                                    //                             let arr = []
                                    //                             that.state.apps.forEach((vv,ii) => {
                                    //                                 if(ii !== index) {
                                    //                                     arr.push(vv)
                                    //                                 }
                                    //                             })
                                    //                             this.setState({
                                    //                                 apps:arr
                                    //                             })
                                    //                         } else {
                                    //                             Toast.fail("操作失败",1)
                                    //                         }
                                    //                     })
                                    //                 })
                                    //                 .catch(err=>{
                                    //                     //
                                    //                 })
                                    //         },
                                    //         style: { backgroundColor: 'rgb(255,255,255,0.9)', color: 'white'},
                                    //     }
                                    // ]}
                                    onOpen={()=>{
                                        let msgs = this.props.historyMes.message.filter(e=>e.appName === v.appName && this.arrDiff(e.parameters,v.parameters) && !e.isDeleted)
                                        let msgsId = []
                                        msgs.map(v => {
                                            msgsId.push(v._id)
                                        })
                                        this.props.setDetailParameters(v.parameters)
                                        this.props.setParameters(v.parameters)
                                        this.setState({msgsId:msgsId})
                                        console.log(msgsId,this.state.msgsId)
                                    }}
                                    onClose={() => {
                                        console.log('global close')
                                    }
                                    }
                                >
                                    <List.Item
                                        arrow="horizontal"
                                        thumb={v.icon}
                                        extra={

                                            <Badge text={this.props.historyMes.message.filter(ii=>ii.appName === v.appName && this.arrDiff(ii.parameters,v.parameters) && !ii.hasRead && !ii.isDeleted).length} overflowCount={99} />
                                        }
                                        // multipleLine={true}
                                        onClick={() =>
                                        {
                                            let descriptions = ''
                                            if (v.parameters.length>0) {
                                                v.parameters.map((e, indx)=> {
                                                    descriptions += v.paramsDescription[indx].showTitle+': ' + e.value + ' '
                                                })
                                            } else {
                                                descriptions = '无参数'
                                            }
                                            this.props.setParameters(v.parameters,descriptions)
                                            this.props.history.push(`/historyMes:${v.appName}`)
                                        }}
                                    >
                                        {v.appName}
                                        <List.Item.Brief>{v.description}</List.Item.Brief>
                                        <List.Item.Brief>
                                            {
                                                v.description!=='应用已下架' && v.parameters.length>0 ? v.parameters.map((e, indx)=> (
                                                    <div style={{display:'inline'}}>
                                                        {v.paramsDescription[indx].showTitle+': ' + e.value + ' '}
                                                    </div>
                                                    )
                                                )
                                                    :<div style={{display:'inline'}}>
                                                        {v.description!=='应用已下架'?'无参数':' '}
                                                    </div>
                                            }
                                            </List.Item.Brief>
                                    </List.Item>
                                </SwipeAction>
                            ))
                            :null
                    }
                </List>
            </div>

        )
    }
}
export default MyService
