import React from 'react'
import { List, WhiteSpace ,InputItem,NoticeBar, Card, WingBlank } from 'antd-mobile'
import {Toast , Picker} from 'antd-mobile'
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux"

import {App} from "../../config/config"
import City from "./city2.json"
import District from "./district.json"
import Province from "./province.json"
import {useThisApp,setAppValue,initDetail} from "../../redux/detail.redux"
@withRouter
@connect(
    // state=>state.detail,
    state=>({
        historyMes:state.historyMes,
        detail:state.detail,
        keyBoardHeight:0,
    }),
    {useThisApp,setAppValue,initDetail}
)
class Detail extends React.Component {
    componentWillMount(){
        this.setState({
            city:'请选择',
            height:window.innerHeight,
            innerHeight:window.innerHeight
        })
    }
    componentDidMount() {
        const appName = this.props.location.pathname.match( /\/detail:(\S*)/)[1]
        this.props.useThisApp(appName)
        document.getElementsByClassName('scrollDiv')[0].parentNode.style = "overflow-y: scroll;height:600px"
        window.addEventListener('resize', () => {
            if (window.location.href.indexOf('detail')>=0) {
                let keyBoardHeight = this.state.innerHeight - window.innerHeight
                this.setState({
                    height:keyBoardHeight>0?800:window.innerHeight,
                    keyBoardHeight:keyBoardHeight
                })
                let needHeight = document.getElementsByClassName('am-card')[1].offsetHeight+190
                if (window.innerHeight<needHeight) {
                    document.getElementsByClassName('scrollDiv')[0].parentNode.scrollTop = needHeight - window.innerHeight
                } else {
                    document.getElementsByClassName('scrollDiv')[0].parentNode.scrollTop = 0
                }
            }
        })
    }

    render() {
        if(this.props.detail.isRssSuccess) {
            this.props.history.push("/myservice")
            // this.props.history.push("/myservice")
        }
        const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent)
        let moneyKeyboardWrapProps
        if (isIPhone) {
            moneyKeyboardWrapProps = {
                onTouchStart: e => e.preventDefault(),
            }
        }
        let findVal = (key,parameters) => {
            let val = ""
            parameters.forEach(v=>{
                if(v.key === key) {
                    val = v.value
                }
            })
            return val
        }
        return (
            <div className={'scrollDiv'} style={{height:this.state.height,overflowY:'scroll'}}>
                {/*<WingBlank size="lg">*/}
                <WhiteSpace/>


                <Card>
                    <Card.Header
                        title= {this.props.detail.detail.appName}
                        thumb= {this.props.detail.detail.icon}
                        thumbStyle = {{width:'30px',height:'30px'}}
                    />
                    <Card.Body>
                        <div>应用描述：{this.props.detail.detail.description}</div>
                    </Card.Body>
                    <Card.Footer content={'发布者：'+this.props.detail.detail.developer} />
                </Card>
                <WhiteSpace/>

                <Card>
                    <Card.Header
                        title= {'应用参数配置'}
                        thumb= {'http://47.94.139.223:9002/upload/setting.jpeg'}
                        thumbStyle = {{width:'30px',height:'30px'}}
                    />
                    <Card.Body>
                        {/*<div>{'应用描述：'+this.props.detail.description}</div>*/}

                        <List>
                            {
                                this.props.detail.detail.paramsDescription.length === 0 ?
                                    <NoticeBar marqueeProps={{ loop: true, style: { padding: '0 7.5px' } }}>
                                        此应用无需配置，直接订阅。
                                    </NoticeBar>
                                    :
                                    this.props.detail.detail.paramsDescription.map((item,index)=>{
                                        if(item.type === App.paramTypeCity) {
                                            return <Picker
                                                key ={`${index}`}
                                                onOk={e => {
                                                    this.props.setAppValue(item.name,e[1].substring(0,e[1].length -3))
                                                    this.setState({city:e[1].substring(0,e[1].length -3)})
                                                }}
                                                extra={findVal(item.name,this.props.detail.userParams.parameters)?findVal(item.name,this.props.detail.userParams.parameters):this.state.city}
                                                data={City}
                                                title="选择地点"
                                                cols={2}
                                                cascade={true}
                                                onDismiss={e => {}}
                                            >
                                                <List.Item arrow="horizontal">{item.showTitle}</List.Item>
                                            </Picker>
                                        } else if(item.type === App.paramTypeString) {
                                            return <InputItem
                                                clear
                                                key ={`${index}`}
                                                placeholder={item.showPlaceholder}
                                                ref={el => this.inputRef = el}
                                                value={findVal(item.name,this.props.detail.userParams.parameters)}
                                                onChange={val=>{
                                                    this.props.setAppValue(item.name,val)
                                                }}
                                            >{
                                                item.showTitle
                                            }</InputItem>
                                        } else if(item.type === App.paramTypeInteger) {
                                            return <InputItem
                                                key ={`${index}`}
                                                type={"number"}
                                                placeholder={item.showPlaceholder}
                                                clear
                                                moneyKeyboardAlign="left"
                                                moneyKeyboardWrapProps={moneyKeyboardWrapProps}
                                                value={findVal(item.name,this.props.detail.userParams.parameters)}
                                                onChange={val=>{
                                                    if(!isNaN(val)) {
                                                        this.props.setAppValue(item.name,parseInt(val))
                                                    } else {
                                                        Toast.fail("只能输入数字",1,()=>{
                                                            this.props.setAppValue(item.name,"")
                                                        })
                                                    }
                                                }}
                                            >{
                                                item.showTitle
                                            }</InputItem>
                                        } else if(item.type === App.paramTypeProvinceZN) {
                                            return <Picker
                                                key ={`${index}`}
                                                data={Province}
                                                cols={1}
                                                extra={findVal(item.name,this.props.detail.userParams.parameters) ? findVal(item.name,this.props.detail.userParams.parameters)  :"请选择"}
                                                className="forss"
                                                onOk={e => {
                                                    this.props.setAppValue(item.name,e[0])
                                                }}
                                                onDismiss={e => {}}
                                            >
                                                <List.Item arrow="horizontal">{item.showTitle}</List.Item>
                                            </Picker>
                                        }else if(item.type === App.paramTypeCityZN) {
                                            return <Picker
                                                key ={`${index}`}
                                                data={District}
                                                cols={2}
                                                extra={findVal(item.name,this.props.detail.userParams.parameters) ? findVal(item.name,this.props.detail.userParams.parameters)  :"请选择"}
                                                className="forss"
                                                onOk={e => {
                                                    this.props.setAppValue(item.name,`${e[0]},${e[1]}`)
                                                }}
                                                onDismiss={e => {}}
                                            >
                                                <List.Item arrow="horizontal">{item.showTitle}</List.Item>
                                            </Picker>
                                        } else if(item.type === App.paramTypeDistrictZN) {
                                            return <Picker
                                                key ={`${index}`}
                                                data={District}
                                                cols={3}
                                                extra={findVal(item.name,this.props.detail.userParams.parameters) ? findVal(item.name,this.props.detail.userParams.parameters)  :"请选择"}
                                                className="forss"
                                                onOk={e => {
                                                    this.props.setAppValue(item.name,`${e[0]},${e[1]},${e[2]}`)
                                                }}
                                                onDismiss={e => {}}
                                            >
                                                <List.Item arrow="horizontal">{item.showTitle}</List.Item>
                                            </Picker>
                                        }
                                    })
                            }
                        </List>

                    </Card.Body>
                    {/*<Card.Footer content={'发布者：'+this.props.detail.developer} />*/}
                </Card>
                <WhiteSpace size="lg" />

                {/*</WingBlank>*/}


            </div>
        )
    }
}
export default Detail
