import React from 'react'
import {withRouter} from 'react-router-dom'

@withRouter
class DisableBack extends React.Component {
    componentWillMount() {
        setTimeout(() => {
            window.history.forward()
        }, 0)
        window.onunload=function(){
            //
        };
    }
    render() {
        return null
    }
}

export default DisableBack
