import React from 'react'
import { List ,SwipeAction ,SearchBar ,WhiteSpace,TextareaItem, Button, WingBlank,ListView} from 'antd-mobile'
import {withRouter} from "react-router-dom"
import {SendFeedback} from "../../api/serviceConfig"
import {connect} from "react-redux"
import {setTitle,setContent} from "../../redux/feedback.redux";
import {Toast} from "antd-mobile";
import {FETCH} from "../../util/fetch"
import EditImg from "../myService/edit.png"
import DelImg from "../myService/del.png"
import './feedback.css'

@withRouter
@connect(
    // state=>state.detail,
    state=>({
        feedback:state.feedback
    }),
    {setTitle,setContent}
)
class Feedback extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title:this.props.feedback.title?this.props.feedback.title:'',
            content:this.props.feedback.content?this.props.feedback.content:'',
            height:window.innerHeight
        }
    }
    componentDidMount() {
        document.getElementsByClassName('feedbackContent')[0].parentNode.style = "height:"+window.innerHeight-100
    }

    render() {
        const phone = this.props.location.pathname.match( /\/feedback:phone=(\S*)/)[1]
        const deviceId = window.localStorage.getItem("deviceId")
        let that = this
        return (
            <div className={'feedbackContent'}>
                    <WhiteSpace/>
                    <List>
                        <TextareaItem
                            title="标题"
                            autoHeight
                            labelNumber={3}
                            placeholder="请填写标题"
                            value={this.state.title}
                            onChange={v => {
                                that.setState({title:v})
                                that.props.setTitle(v)
                            }}
                        />
                        <TextareaItem
                            rows={parseInt(this.state.height/25)}
                            placeholder="请填写您的想法或反馈建议"
                            value={this.state.content}
                            onChange={v => {
                                that.setState({content:v})
                                that.props.setContent(v)
                            }}
                        />
                    </List>
            </div>
        )
    }
}
export default Feedback
