import React from 'react'
import { List ,SwipeAction ,SearchBar ,WhiteSpace,Card,Switch,Button, WingBlank,ListView} from 'antd-mobile'
import {withRouter} from "react-router-dom"
import {connect} from "react-redux"
import {SetAllowNotice, GetThisAllowedOrNot, DeleteThisAppMsgs} from "../../api/serviceConfig"
import {delMes, getMyMes, hasReadMes,deleteAllMsgs} from "../../redux/historyMes.redux";
import {setFeedback} from "../../redux/feedback.redux"
import {FETCH} from "../../util/fetch"
import {Toast} from "antd-mobile"
import swal from "sweetalert"
@withRouter
@connect(
    state=>state,
    {getMyMes,hasReadMes,delMes,deleteAllMsgs,setFeedback}
)

class SetSubscription extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            checked:false,
            paramsDescription:[],
            icon:''
        }
    }
    componentWillMount(){
        const pathname = this.props.location.pathname
        const appName = pathname.match( /\/setSubscription:(\S*)/)[1]
        const deviceId = window.localStorage.getItem("deviceId")
        const parameters = this.props.historyMes.parameters
        FETCH(GetThisAllowedOrNot,{
            method:"POST",
            mode:"cors",
            headers: {
                "Content-Type": "application/json"
            },
            body:JSON.stringify({
                appName:appName,
                deviceId:deviceId,
                parameters:parameters
            })
        }).then(res=>{
            res.json().then(data=>{
                console.log(data)
                this.setState({
                    checked:data.data&&data.data.allowNotice? false:true,
                    paramsDescription:data.data&&data.data.paramsDescription?data.data.paramsDescription:[],
                    icon:data.data&&data.data.icon?data.data.icon:''
                })
            })
        }).catch(err=>{

        })
    }

    render() {
        const pathname = this.props.location.pathname
        const appName = pathname.match( /\/setSubscription:(\S*)/)[1]
        const deviceId = window.localStorage.getItem("deviceId")
        console.log(this.props)
        const parameters = this.props.historyMes.parameters
        return(
            <div>
                <WhiteSpace/>
                <Card>
                    <Card.Header
                        title= {appName}
                        thumb= {this.state.icon}
                        thumbStyle = {{width:'30px',height:'30px'}}
                    />
                    <Card.Body>
                        {
                            parameters && parameters.length>0 && parameters.map(param => (
                                <div>{`${this.state.paramsDescription.length>0
                                &&this.state.paramsDescription.filter(
                                    description =>{return description.name === param.key}
                                )[0].showTitle}: ${param.value}`}</div>
                            ))
                        }
                        {
                            parameters.length===0?
                                <div>此订阅无参数</div>
                                :null
                        }
                    </Card.Body>
                </Card>
                <WhiteSpace/>
                <List.Item
                    extra={<Switch
                        checked={this.state.checked}
                        onChange={() => {
                            FETCH(SetAllowNotice,{
                                method:"POST",
                                mode:"cors",
                                headers: {
                                    "Content-Type": "application/json"
                                },
                                body:JSON.stringify({
                                    appName:appName,
                                    deviceId:deviceId,
                                    parameters:parameters,
                                    isAllowed:this.state.checked
                                })
                            }).then(res=>{
                                res.json().then(data=>{
                                    console.log(data)
                                    if (data.code === 0){
                                        this.setState({
                                            checked:!this.state.checked
                                        })
                                        Toast.success('设置成功')
                                    }

                                })
                            }).catch(err=>{

                            })
                        }}
                    />}
                >消息免打扰</List.Item><WhiteSpace/>
                <List.Item
                    onClick={()=>{
                        swal({
                            title:"清空消息记录",
                            text: "是否清空此订阅消息记录",
                            // icon: "warning",
                            buttons:["取消","确认"],
                            buttons:{
                                cancel: "取消",
                                confirm: "确认",
                                roll: {
                                    text: "",
                                    value: "roll",
                                },
                            },
                            // dangerMode: true,
                            closeOnConfirm: false,
                            closeOnCancel: false
                        })
                            .then((willDelete)=>{
                                if (willDelete){
                                    FETCH(DeleteThisAppMsgs,{
                                        method:"DELETE",
                                        mode:"cors",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body:JSON.stringify({
                                            appName:appName,
                                            deviceId:deviceId
                                        })
                                    })
                                        .then(res => {
                                            res.json().then(data=>{
                                                if(data.code === 0) {
                                                    Toast.success('已清空消息记录',1)
                                                    this.props.deleteAllMsgs()
                                                } else {
                                                    Toast.fail("操作失败",1)
                                                }
                                            })
                                        })
                                        .catch(err=>{
                                            //
                                        })
                                }
                            })
                    }}
                >清空消息记录
                </List.Item><WhiteSpace/>
                <List.Item
                    arrow="horizontal"
                    onClick={()=>{
                        this.props.setFeedback({
                            title:`来自应用:${appName}`,
                            content:
                                `${this.props.historyMes.descriptions?this.props.historyMes.descriptions:'无参数'}

`
                        })
                        this.props.history.push('/feedback:phone='+window.localStorage.getItem("phone"))
                    }}
                >反馈
                </List.Item>
            </div>
        )
    }
}
export default SetSubscription
