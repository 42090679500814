import {FETCH} from "../util/fetch"
import {SendFeedback} from "../api/serviceConfig"
import {Toast} from "antd-mobile"

const initState = {
    title:'',
    content:''
}

const INIT = "INIT"
const SETFEEDBACK = "SETFEEDBACK"

export function feedback(state=initState,action) {
    switch(action.type) {
        case INIT:
        case SETFEEDBACK:
            return action.payload
        default:
            return state
    }
}



export function setFeedback(feedback) {
    return async(dispatch,getState) => {
        dispatch({
            type:SETFEEDBACK,
            payload:{
                title:feedback.title,
                content:feedback.content
            }
        })
    }
}

export function setTitle(title) {
    return async (dispatch, getState) =>{
        const content = getState().feedback.content
        dispatch({
            type:SETFEEDBACK,
            payload:{
                title:title,
                content:content
            }
        })
    }
}

export function setContent(content) {
    return async (dispatch, getState) =>{
        const title = getState().feedback.title
        console.log(content)
        dispatch({
            type:SETFEEDBACK,
            payload:{
                title:title,
                content:content
            }
        })
    }
}

export function sendFeedback(phone,deviceId) {
    return async (dispatch,getState) => {
        let isSuccess = true
        const title = getState().feedback.title
        const content = getState().feedback.content
        if (content){
            FETCH(SendFeedback,{
                method:"POST",
                mode:"cors",
                headers: {
                    "Content-Type": "application/json"
                },
                body:JSON.stringify({
                    phone:phone,
                    deviceId:deviceId,
                    title:title,
                    msg:content,
                })
            }).then(res => {
                res.json().then(data =>{
                    if (data.code === 0) {
                        Toast.success('反馈提交成功')
                        dispatch({
                            type:SETFEEDBACK,
                            payload:{
                                title:'',
                                content:''
                            }
                        })
                    } else {
                        Toast.fail('反馈提交失败，请稍后重试')
                        isSuccess = false
                    }
                })
            }).catch(err => {
                Toast.fail('反馈提交失败，请稍后重试')
                isSuccess = false
            });
        } else {
            Toast.fail('填写反馈内容后再提交')
            isSuccess = false
        }
        return isSuccess
    }
}
